import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/Ajustes.css'; // Ensure the path is correct
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, getDoc, updateDoc, getDocs, query, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import Sidebar from './Sidebar'; // Ensure the path is correct
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../CSS/HomePage.css';
import Logo from '../../../Assets/wai-logo-black.png';
import {auth, db} from '../../../firebase';
import Modal from 'react-modal';
import CreatableSelect from 'react-select/creatable';

const Ajustes = () => {
  const navigate = useNavigate();
  const [activeLink, setActiveLink] = useState('ajustes');
  const [profileData, setProfileData] = useState({});
  const [bankData, setBankData] = useState({});
  const [managerData, setManagerData] = useState({});
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const [activeSection, setActiveSection] = useState('ajustes');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 750) {
        setShowLeftSidebar(false);
      } else {
        setShowLeftSidebar(true);
      }
    };

    window.addEventListener('resize', handleResize);
    // Set initial state based on window size
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.error("User not authenticated");
        return;
      }

      try {
        const brandsUserCollection = collection(db, 'brands_profile_test');
        const q = query(brandsUserCollection, where("uid", "==", user.uid));
        const querySnapshot = await getDocs(q);
        
        if (!querySnapshot.empty) {
          const brandDoc = querySnapshot.docs[0];
          setProfileData(brandDoc.data());

          const privateCollection = collection(brandDoc.ref, 'private');

          const bankDataDoc = doc(privateCollection, 'bank_data');
          const bankDataSnapshot = await getDoc(bankDataDoc);
          console.log(bankDataSnapshot.data());
          if (bankDataSnapshot.exists()) {
            setBankData(bankDataSnapshot.data());
            console.log(bankDataSnapshot.data());
          }

          const managerDataDoc = doc(privateCollection, 'brand_data');
          const managerDataSnapshot = await getDoc(managerDataDoc);
          if (managerDataSnapshot.exists()) {
            setManagerData(managerDataSnapshot.data());
          }
        } else {
          console.error("No matching brand document found");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [auth.currentUser, db]);

  const handleInputChange = (e, setState) => {
    const { name, value } = e.target;
    setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const bankOptions = [
    { value: "n1u", label: "n1u" },
    { value: "Banco Ciudad", label: "Banco Ciudad" },
    { value: "Banco Comafi", label: "Banco Comafi" },
    { value: "Banco Credicoop", label: "Banco Credicoop" },
    { value: "Banco de Galicia", label: "Banco de Galicia" },
    { value: "Banco de la Nacion", label: "Banco de la Nacion" },
    { value: "Banco Hipotecario", label: "Banco Hipotecario" },
    { value: "Banco Macro", label: "Banco Macro" },
    { value: "Banco Patagonia", label: "Banco Patagonia" },
    { value: "Banco Provincia", label: "Banco Provincia" },
    { value: "Banco Santander Río", label: "Banco Santander Río" },
    { value: "Banco Supervielle", label: "Banco Supervielle" },
    { value: "BBVA Argentina", label: "BBVA Argentina" },
    { value: "Belo", label: "Belo" },
    { value: "BNA +", label: "BNA +" },
    { value: "Brubank", label: "Brubank" },
    { value: "Cuenta DNI", label: "Cuenta DNI" },
    { value: "HSBC", label: "HSBC" },
    { value: "ICBC", label: "ICBC" },
    { value: "Lemon", label: "Lemon" },
    { value: "Mercado Pago", label: "Mercado Pago" },
    { value: "Naranja X", label: "Naranja X" },
    { value: "Personal Pay", label: "Personal Pay" },
    { value: "Uala", label: "Uala" }
  ];

  const handleBankChange = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';
    setBankData(prevState => ({
      ...prevState,
      bank_account_name: value
    }));
  };

  const handleSaveChanges = async () => {
    const requiredFields = {
      'Brand Name': profileData.brand_name,
      'Bank Transfer Number ID': bankData.bank_transfer_number_id,
      'Bank Account CUIT': bankData.bank_account_cuit,
      'Bank Account DNI': bankData.bank_account_dni,
      'Bank Account Name': bankData.bank_account_name,
      'Bank Account Owner Name': bankData.bank_account_owner_name,
      'Brand Contact Email': profileData.brand_contact_email,
      'Brand Phone': managerData.brand_phone,
      'Brand Origin Address': managerData.brand_origin_address,
      'Brand Origin Locality': managerData.brand_origin_locality,
      'Brand Origin City': managerData.brand_origin_city,
      'Brand Origin Province': managerData.brand_origin_province,
      'Brand Origin Postal Code': managerData.brand_origin_postal_code
    };

    const missingFields = Object.entries(requiredFields)
      .filter(([_, value]) => !value)
      .map(([key, _]) => key);

    if (missingFields.length > 0) {
      const missingFieldsMessage = missingFields.join(', ');
      toast.error(`Please fill in the following required fields: ${missingFieldsMessage}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      console.error("User not authenticated");
      return;
    }

    setIsSaving(true);

    try {
      const brandsUserCollection = collection(db, 'brands_profile_test');
      const q = query(brandsUserCollection, where("uid", "==", user.uid));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.error("No matching brand document found");
        return;
      }

      const brandDoc = querySnapshot.docs[0].ref;
      await updateDoc(brandDoc, profileData);

      const privateCollection = collection(brandDoc, 'private');

      const bankDataDoc = doc(privateCollection, 'bank_data');
      await updateDoc(bankDataDoc, bankData);

      const managerDataDoc = doc(privateCollection, 'brand_data');
      await updateDoc(managerDataDoc, managerData);

      toast.success("Data saved successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error saving data: ", error);
      toast.error("Error saving data.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleNavigation = (path) => {
    setActiveLink(path);
    navigate(`/${path}`);
  };

  const handleSignOut = async () => {
    closeModal();
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success('Link copiado al portapapeles!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }).catch(err => {
      console.error('Error al copiar al portapapeles: ', err);
      toast.error('Error al copiar el link. Por favor, inténtalo de nuevo.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    });
  };

  return (
    <div className="content">
      <div className="home-page-container">
        <div className="main-content">
          <div className="user-profile-container">
          { !showLeftSidebar && <div className="user-profile-sidebar">
              <div className='logo' style={{width:'140px', height:'60px', alignItems:'left', justifyContent:'left'}}>
                <img src={Logo} className='logo-img' style={{ width: '100%', height: '100%',alignItems:'left', justifyContent:'left', objectFit: 'contain', padding:'0px' }} />
              </div>
              <ul className="user-profile-sidebar-links">
                <li className={activeSection === 'dashboard' ? 'active' : ''} onClick={() => handleNavigation('dashboard')}>Dashboard</li>
                <li className={activeSection === 'ventas' ? 'active' : ''} onClick={() => handleNavigation('ventas')}>Ventas</li>
                <li className={activeSection === 'ajustes' ? 'active' : ''} onClick={() => handleNavigation('ajustes')}>Ajustes</li>
                <li onClick={openModal}>Cerrar Sesion</li>
              </ul>
            </div>}
            <div className="user-profile-settings">
              {showLeftSidebar && <div className="user-profile-sidebar">
                <ul className="user-profile-sidebar-links">
                    <li className={activeSection === 'dashboard' ? 'active' : ''} onClick={() => handleNavigation('dashboard')}>Dashboard</li>
                    <li className={activeSection === 'ventas' ? 'active' : ''} onClick={() => handleNavigation('ventas')}>Ventas</li>
                    <li className={activeSection === 'ajustes' ? 'active' : ''} onClick={() => handleNavigation('ajustes')}>Ajustes</li>
                  <li onClick={openModal}>Cerrar Sesion</li>
                </ul>
              </div>}
              <div className="profile-section">
              <div className="profile-header">
                <img style={{objectFit: 'contain'}} src={profileData.brand_profile_image || 'default-image-url'} alt="Profile" className="profile-image"/>
                <div className="input-group">
                  <label>
                    <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>Nombre:</span>
                    <input 
                      type="text" 
                      name="brand_name" 
                      value={profileData.brand_name || ''} 
                      onChange={(e) => handleInputChange(e, setProfileData)}
                      placeholder="Nombre"
                    />
                  </label>
                </div>
              </div>
              <div className="input-group">
                <label>
                  <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>Descripcion:</span>
                  <textarea 
                    name="brand_description" 
                    value={profileData.brand_description || ''} 
                    onChange={(e) => handleInputChange(e, setProfileData)}
                    placeholder="Descripcion"
                  />
                </label>
              </div>
              <button 
                  onClick={() => copyToClipboard(`https://wearlyapp.com/brand/${profileData.wearly_brand_id}`)}
                  style={{
                    backgroundColor: 'black',
                    color: 'white',
                    fontWeight: 'bold',
                    padding: '10px 50px',
                    borderRadius: '7px',
                    cursor: 'pointer',
                    border: 'none',
                    width: '100%',
                    marginBottom: '10px'
                  }}
                >
                  Comparti tu Tienda!
                </button>
                <a href={`https://wearlyapp.com/brand/${profileData.wearly_brand_id}`} target="_blank" rel="noreferrer">
                  {`https://wearlyapp.com/brand/${profileData.wearly_brand_id}`}
                </a>
            </div>
            
            <h2>Datos Bancarios</h2>
            <div className="bank-section">
              <div className="input-group">
                <label>
                  <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>CBU/Alias:</span>
                  <input 
                    type="text" 
                    name="bank_transfer_number_id" 
                    value={bankData.bank_transfer_number_id || ''} 
                    onChange={(e) => handleInputChange(e, setBankData)}
                    placeholder="CBU/Alias"
                  />
                </label>
              </div>
              <div className="input-group">
                <label>
                  <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>CUIT/CUIL:</span>
                  <input 
                    type="text" 
                    name="bank_account_cuit" 
                    value={bankData.bank_account_cuit || ''} 
                    onChange={(e) => handleInputChange(e, setBankData)}
                    placeholder="CUIT/CUIL"
                  />
                </label>
              </div>
              <div className="input-group">
                <label>
                  <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>DNI:</span>
                  <input 
                    type="text" 
                    name="bank_account_dni" 
                    value={bankData.bank_account_dni || ''} 
                    onChange={(e) => handleInputChange(e, setBankData)}
                    placeholder="DNI"
                  />
                </label>
              </div>
              <div className="input-group">
                <label>
                  <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>Banco:</span>
                  <CreatableSelect
                    isClearable
                    options={bankOptions}
                    value={bankData.bank_account_name ? { value: bankData.bank_account_name, label: bankData.bank_account_name } : null}
                    onChange={handleBankChange}
                    placeholder="Seleccione o escriba el nombre del banco"
                  />
                </label>
              </div>
            </div>
            
            <h2>Datos del Admin</h2>
            <div className="personal-section">
              <div className="input-group">
                <label>
                  <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>Email:</span>
                  <input 
                    type="text" 
                    name="brand_contact_email" 
                    value={profileData.brand_contact_email || ''} 
                    onChange={(e) => handleInputChange(e, setProfileData)}
                    placeholder="Email"
                  />
                </label>
              </div>
              <div className="input-group">
                <label>
                  <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>Telefono:</span>
                  <input 
                    type="text" 
                    name="brand_phone" 
                    value={managerData.brand_phone || ''} 
                    onChange={(e) => handleInputChange(e, setManagerData)}
                    placeholder="Telefono"
                  />
                </label>
              </div>
              <div className="input-group">
                <label>
                  <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>Calle:</span>
                  <input 
                    type="text" 
                    name="brand_origin_address" 
                    value={managerData.brand_origin_address || ''} 
                    onChange={(e) => handleInputChange(e, setManagerData)}
                    placeholder="Calle"
                  />
                </label>
              </div>
              <div className="input-group">
                <label>
                  <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>Nº de Calle:</span>
                  <input 
                    type="text" 
                    name="brand_origin_number" 
                    value={managerData.brand_origin_number || ''} 
                    onChange={(e) => handleInputChange(e, setManagerData)}
                    placeholder="Nº de Calle"
                  />
                </label>
              </div>
              <div className="input-group">
                <label>
                  <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>Piso:</span>
                  <input 
                    type="text" 
                    name="brand_origin_floor" 
                    value={managerData.brand_origin_floor || ''} 
                    onChange={(e) => handleInputChange(e, setManagerData)}
                    placeholder="Piso"
                  />
                </label>
              </div>
              <div className="input-group">
                <label>
                  <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>Barrio:</span>
                  <input 
                    type="text" 
                    name="brand_origin_locality" 
                    value={managerData.brand_origin_locality || ''} 
                    onChange={(e) => handleInputChange(e, setManagerData)}
                    placeholder="Barrio"
                  />
                </label>
              </div>
              <div className="input-group">
                <label>
                  <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>Ciudad:</span>
                  <input 
                    type="text" 
                    name="brand_origin_city" 
                    value={managerData.brand_origin_city || ''} 
                    onChange={(e) => handleInputChange(e, setManagerData)}
                    placeholder="Ciudad"
                  />
                </label>
              </div>
              <div className="input-group">
                <label>
                  <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>Provincia:</span>
                  <select 
                    name="brand_origin_province" 
                    value={managerData.brand_origin_province || ''} 
                    onChange={(e) => handleInputChange(e, setManagerData)}
                    style={{
                      width: 'calc(100% - 20px)',
                      padding: '10px',
                      margin: '10px 0',
                      border: 'none',
                      borderRadius: '4px',
                      fontSize: '12px',
                      fontWeight: '700',
                      backgroundColor: '#f0f0f0',
                      appearance: 'none',
                      backgroundImage: 'url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E")',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'right 10px top 50%',
                      backgroundSize: '12px auto'
                    }}
                  >
                    <option value="">Seleccionar Provincia</option>
                    {["Buenos Aires", "Ciudad Autónoma de Buenos Aires", "Catamarca", "Chaco", "Chubut", "Córdoba", "Corrientes", "Entre Ríos", "Formosa", "Jujuy", "La Pampa", "La Rioja", "Mendoza", "Misiones", "Neuquén", "Río Negro", "Salta", "San Juan", "San Luis", "Santa Cruz", "Santa Fe", "Santiago del Estero", "Tierra del Fuego", "Tucumán"].map((province) => (
                      <option key={province} value={province}>{province}</option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="input-group">
                <label>
                  <span style={{ fontSize: '12px', marginBottom: '5px', display: 'block' }}>Codigo Postal:</span>
                  <input 
                    type="text" 
                    name="brand_origin_postal_code" 
                    value={managerData.brand_origin_postal_code || ''} 
                    onChange={(e) => handleInputChange(e, setManagerData)}
                    placeholder="Codigo Postal"
                  />
                </label>
              </div>
            </div>
            <button className='button-settings' onClick={handleSaveChanges} disabled={isSaving} style={{ display: isSaving ? 'none' : 'block' }}>Guardar Cambios</button>
          </div>
        </div>
        </div>
      </div>
      <ToastContainer />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Sign Out"
        className="user-profile-modal-sign-out"
        overlayClassName="user-profile-modal-sign-out-overlay"
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10000
          }
        }}
      >
        <div style={{marginTop: 0, backgroundColor: '#FFF', padding: '20px', borderRadius: '5px'}} className="user-profile-modal-sign-out-content">
          <h2 className="user-profile-modal-sign-out-header">Confirm Sign Out</h2>
          <p className="user-profile-modal-sign-out-text">Are you sure you want to sign out?</p>
          <div className="user-profile-modal-sign-out-buttons">
            <button onClick={handleSignOut} className="user-profile-modal-sign-out-button" style={{ backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px', padding: '10px 20px', margin: '5px', cursor: 'pointer' }}>Yes</button>
            <button onClick={closeModal} className="user-profile-modal-sign-out-button" style={{ backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '4px', padding: '10px 20px', margin: '5px', cursor: 'pointer' }}>No</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Ajustes;
